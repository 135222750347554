.rent-wrapper {
    min-height: 120px;
    padding-right: 1rem;
    padding-left: 1rem;
    h6 {
        font-weight: 400;
        text-align: center;
    }
    .range{
        padding-top: 3.5rem;
        padding-bottom: 1rem;
        border-radius: 0;
        &.rc-slider {
            height: 16px;
        }
        .rc-slider-rail {
            overflow: hidden;
            height: 17px;
            border-radius: unset;
            background-color: transparent;
        }
        .rc-slider-rail:before {
            content: '';
            width: 100%;
            background-color: $red;
            height: 4px;
            position: absolute;
            transform-origin: top right;
        }
        .rc-slider-step {
            .rc-slider-dot {
                display: none;
            }
        }
        .rc-slider-track {
            display: none;
        }

        .rc-slider-handle {
            border: solid 1px $grey;
            height: 14px;
            width: 14px;
            margin-top: -6px;
            border-radius: 50%;
        }
        .rc-slider-handle-1 {
            margin-left:-1px;
        }
        .rc-slider-handle-2 {
            margin-left:-2px;
        }
        .rc-slider-mark {
            bottom: 0;
            .rc-slider-mark-text {
                bottom: -22px;
                font-size: 11px;
                line-height: 14px;
                color: $black;
                &:last-child {
                    left: 94% !important;
                    &:after {
                        display: none;
                    }
                }
                &:first-child{
                    left: 19px !important;
                    &:after {
                        display: none;
                    }
                }
                &:after {
                    content: "";
                    height: 8px;
                    width: 1px;
                    background-color: $grey;
                    position: absolute;
                    transform: translateX(-50%);
                    left: 50%;
                    top: -10px;
                }
                @include media-breakpoint-down(md) {
                    &:last-child {
                        left: 95% !important;
                    }
                    &:first-child{
                        left: 3% !important;
                    }
                }
                @include media-breakpoint-down(xs) {
                    &:last-child {
                        left: 93% !important;
                    }
                    &:first-child{
                        left: 6% !important;
                    }
                }
                
            }
        }
        .rc-slider-mark:before, .rc-slider-mark:after {
            content: '';
            height: 8px;
            width: 1px;
            background-color: $grey;
            position: absolute;
            bottom: -3px;
            left: 5px;
            z-index: 1;
        }
        .rc-slider-mark:after {
            right: -5px;
            left: unset;
        }
    }
}