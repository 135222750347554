.paragraph-block{
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $grey-l3;
    }
    .text-container{
        @include media-breakpoint-down(md){
            margin-top: 20px;
        }
        h2{
            font-size: calc(22px + (28 - 22) * ((100vw - 414px) / (1920 - 414)));
            line-height: calc(32px + (38 - 32) * ((100vw - 414px) / (1920 - 414)));
            margin-bottom: 25px;
        }
        i{
            font-style: normal;
            color: $red;
        }
        p{
            font-size: 16px;
            line-height: 24px;
            @include media-breakpoint-down(sm){
                font-size: 14px;
                line-height: 22px;
            }
        }
        ul{
            li{
                list-style: none;
                position: relative;
                font-family: 'Inter', sans-serif;
                font-weight: 500;
                &::before{
                    content: '';
                    position: absolute;
                    left: -18px;
                    top: 6px;
                    display: inline-block;
                    transform: rotate(45deg);
                    height: 10px;
                    width: 4px;
                    border-bottom: 2px solid $red;
                    border-right: 2px solid $red;
                }
            }
        }
    }
}