.contact-block{
    padding-top: 80px;
    padding-bottom: 8px;
    .title-container{
        @include media-breakpoint-down(md){
            padding-left: 30px;
        }
        .title{
            font-size: 28px !important;
            line-height: 38px;
        }
        .subtitle{
            display: block;
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 50px;
            color: $grey-d1;
            @include media-breakpoint-down(md){
                font-size: 17px;
                line-height: 24px;
            }
        }
    }
    .row.member-row{
        background-color: $grey-l2;
        border-radius: 10px;
        margin-bottom: 30px;
        @include media-breakpoint-down(sm){
            padding-bottom: 30px;
            padding-left: 30px;
        }
    }
    .image-container{
        img{
            border-radius: 50%;
            height: calc(100% + 20px);
            margin-top: -10px;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
        }
    }
    .name-container{
        font-size: 24px;
        font-weight: 600;
        @include media-breakpoint-down(md){
            font-size: 20px;
        }
        @include media-breakpoint-down(sm){
            margin-top: 30px;
        }
        .member-title{
            color: $red;
        }
    }
    .phone, .email, .member-title{
        font-size: 18px !important;
        line-height: 26px;
        font-weight: 500;
        span{
            display: block;
            font-size: 14px;
            font-weight: 300;
        }
    }
}