html{

}
body {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(180deg,$grey-l2 0%,$grey-l2 100%);
    font-family: 'Inter', sans-serif;
    color: $black;
    overflow-y: scroll;
    &.hamburger-menu-open {
        overflow-y: hidden;
    }
    &.page-standardpage{
        background: $white;
    }
    section {
        min-height: calc(100vh - 282px);
        padding-bottom: 40px;
    }
}
h1{
    font-size: 48px;
    line-height: 56px;
}
h2{
    font-size: 2rem;
    font-weight: 500;
}
h3{
    font-size: 1.5rem;
    font-weight: 500;
}
h4{
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}
h5{
    font-size: 1rem;
    line-height: 1rem;
}
h6{
    font-size: 13px;
}

