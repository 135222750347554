.quote-block{
    padding-top: 100px;
    padding-bottom: 120px;
    h2{
        margin-bottom: 60px;
        i{
            color: $red;
            font-style: normal;
        }
    }
    svg.quote{
        position: absolute;
        width: 30px;
        top: -13px;
        z-index: 1;
    }
    .quote-container{
        background-color: $grey-l2;
        padding: 40px 20px 30px;
        border-radius: 10px;
        margin-bottom: 27px;
        position: relative;
        svg.talk{
            width: 20px;
            position: absolute;
            bottom: -20px;
            left: calc(50% - 10px);
        }
    }
    span{
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 50px;
    }
}