footer {
    padding: 3rem 0 7rem 0;
    border-top: 1px solid $grey;
    white-space: nowrap;
    font-size: 14px;
    line-height: 19px;
    position: relative;
    .detailed-info-wrapper {
        padding-top: 10px;
        .link-container {
            @include media-breakpoint-down(md) {
                padding-top: 20px;
            }
        }
        .footer-info {
            &.alp {
                position: absolute;
                top: -24px;
                @include media-breakpoint-down(md) {
                    top: 0px;
                }
            }
            @include media-breakpoint-down(lg) {            
                &:nth-child(3), &:nth-child(5), &:nth-child(7) {
                    text-align: right;
                }
            }
            @include media-breakpoint-down(xs) {
                &:nth-child(3), &:nth-child(5), &:nth-child(7) {
                    text-align: unset;
                }
            }
        }
        a.footer-info {
            color: $black;
            text-decoration: none;
            &:hover {
                color: $red;
            }
        }
    }
}