.car-chosen{
    //min-height: 992px;
    position: relative;
    // &:before {
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 432px;
    //     background-color: $grey-l2;
    //     bottom: 0px;
    // }
    .sub-car {
        .back-btn {
            display: block;
            position: relative;
            padding-left: 14px;
            text-align: left;
            .arrow-icon {
                height: 0.75rem;
                path {
                    fill: $grey-d2;
                }
            }
            @include media-breakpoint-down(md) {
                z-index: 1;
                padding-left: 0;
                .arrow-icon {
                    position: absolute;
                    top: 0.75rem;
                    left: -18px;
                    height: 0.5rem;
                    width: unset;
                }
            }
        }
    }
}

.sub-car {
    padding-top: 1rem;
    padding-bottom: 2rem;
    min-height: 34vh;
    @include media-breakpoint-down(md) {
        padding-top: 2rem;
    }
    @include media-breakpoint-between(sm, md) {
        &.no-max-width {
            max-width: 768px;
        }
    }
    &.faded {
        opacity: 0.3;
        pointer-events: none;
    }
    .back-btn-search {
        min-height: 120px;
        a.back-btn {
            @include media-breakpoint-down(md) {
                order: 3;
            }
            span {
                display: none;
            }
        }
        @include media-breakpoint-down (sm) {
            margin: 0 auto;
            max-width: 550px;
        }
        @include media-breakpoint-down (xs) {
            max-width: 350px;
        }
        .search-button {
            text-align: right!important;
            padding-right: 1rem;
            .btn-primary {
                background-color: transparent;
            }
            @include media-breakpoint-down(xs) {
                order: 1;
            }
        }
        .rent-wrapper {
            @include media-breakpoint-down(md) {
                order: 4;
            }
            @include media-breakpoint-down(xs) {
                order: 2;
            }
            .rc-slider-with-marks {
                max-width: calc(100% - 12px);
            }
        }
        .nav-text {
            margin-top: 54px;
            font-size: 22px;
            @include media-breakpoint-down(xs) {
                margin-top: 0px;
            }
        }
        .back-btn {
            text-align: left;
            .arrow-icon {
                display: none;
            }
            span {
                @include media-breakpoint-down(md) {
                    font-size: 20px;
                }
            }
        }
    }
    .all-cars {
        .card-wrapper {
            height: 300px;
            width: 354px;
            @include media-breakpoint-between(xs, sm){
                max-width: 275px;
            }
            @include media-breakpoint-down(xs) {
                max-width: 354px;
            }
            .card-car {
                height: 100%;
                .card-information {
                    min-height: 190px;
                    @include media-breakpoint-down(md) {
                        padding-bottom: 80px;
                    }
                    @include media-breakpoint-down(sm) {
                        padding-bottom: 50px;
                        min-height: unset;
                    }
                    img.card-img.img-fluid {
                        @include media-breakpoint-down(lg) {
                            right: 25px;
                            bottom: -95px;
                            max-width: 85%;
                        }
                        @include media-breakpoint-down(md) {
                            right: 35px;
                            max-width: 80%;
                            bottom: 100px;
                        }
                        @include media-breakpoint-down(sm) {
                            max-width: 230px;
                            right: 10px;
                            top: 95px;
                            bottom: unset;
                        }
                        @include media-breakpoint-down(xs) {
                            max-width: 300px;
                            right: 20px;
                        }
                        @media (max-width: 375px) {
                            max-width: 220px;
                            top: 100px;
                            right: unset;
                            left: 50%;
                            transform: translateX(-50%) scaleX(-1);
                        }   
                    }
                }
            }
        }
    }
    .sub-car-groups {
        margin-top: -70px;
        .card-wrapper:hover {
            cursor: pointer;
            text-decoration: none;
        }
        .card {
            height: 300px;
            border-radius: 20px;
            @include media-breakpoint-down(md) {
                height: 200px;
                &.card-cargroup {
                    max-width: 345px;
                    margin: auto;
                }
            }
        }
        .card-body {
            padding: 0;
        }
        .card-information {
            background-color: $white;
            padding: 1.5rem;
            padding-top: 1.75rem;
            border-radius: 20px;    
            color: black;
            text-decoration: none;
            .card-title {
                margin-bottom: 0;
            }
        }
    }

    .single-car {
        @include media-breakpoint-down(lg) {
            padding: 0
        }
        .btn-wrapper {
            position: absolute;
            top: 12.5rem;
            right: 0rem;
            .prev-btn, .next-btn {
                position: relative;
                font-size: 1.25rem;
                color: #121212;
                float: right;
                font-weight: 500;
                border-radius: 50%;
                background-color: $white;
                outline: none;
                box-shadow: 0 0px 10px 0 rgba(0,0,0,0.25);
                width: 45px;
                height: 45px;
                .arrow {
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    left: 50%;
                }
                &.hidden {
                    display: none;
                }
            }
            .prev-btn {
                right: 2rem;
            }
            .next-btn {
                right: -5rem;
                @include media-breakpoint-down(lg) {
                    right: -4rem;
                }
            }
        }
        .card-wrapper {
            color: $black;
            padding: 0;
            padding-top: 2rem;
        }
        .info-img-wrapper {
            @include media-breakpoint-down(md) {
                margin-top: -50px;
            }
        }
        .card-img-wrapper {
            text-align: center;
            z-index: 1;
            .card-img {
                z-index: 1;
                pointer-events: none;
                height: auto;
                @include media-breakpoint-between(sm, md) {
                    width: 650px;
                    max-width: auto;
                }
                @include media-breakpoint-down(sm) {
                    max-width: 440px;
                    width: 90%;
                }
            }
        }
        .detailed-car-information {
            background-color: $red;
            padding-top: 70px;
            border-radius: 20px;
            color: $white;
            margin-top: -100px;
            @include media-breakpoint-down(md) {
                margin-top: -145px;
                border-radius: 0;
            }
            @include media-breakpoint-down(sm) {
                margin-top: -110px;
            }
            .show-extras-wrapper {
                background-color: $red_d1;
                border-radius: 0 0 20px 20px;
                @include media-breakpoint-down(md){
                    border-radius: 0;
                }
            }
            .extras-wrapper {
                padding-left: 70px;
                @include media-breakpoint-down(md){
                    padding-bottom: 2rem;
                }
            }
            .title {
                padding: 0.5rem 1rem 1rem 1rem;
                &.show-extras {
                    position: relative;
                    text-align: center;
                    font-size: .75rem;
                    color: $white;
                    font-weight: 500;
                    width: 100%;
                    &::before {
                        content: "+";
                        font-size: 20px;
                        position: absolute;
                        top: 3px;
                        margin-left: -1rem;
                        font-weight: 400;
                    }
                    cursor: pointer;
                }
                &.show-less {
                    &::before {
                        display: inline-block;
                        transform: rotate(45deg);
                        transition: 0.5s;
                    }

                }
            }
            .extra {
                text-align: left;
                padding-bottom: 0.5rem;
                font-size: 14px;
                font-weight: 500;
                &:after {
                    content: '';
                    display: block;
                    width: 6px;
                    height: 10px;
                    border: solid $white;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    position: absolute;
                    left: -2px;
                    top: 4px;
                }
            }
        }
        .car-information {
            .car-header {
                padding: 0;
                @include media-breakpoint-down(md) {
                    padding-left: 45px;
                }
                .card-information {
                    background-color: $white;
                    width: 100%;
                    padding: 5px 0 0.8rem 0;
                    .card-title {
                        .group {
                            font-size: 18px;
                            line-height: 25px;
                            font-weight: 500;
                        }
                        h3 {
                            font-size: 28px;
                            font-weight: 400;
                        }
                    }
                    .card-img {
                        max-width: 60%;
                        z-index: 1;
                        left: 20%;
                        top: 12%;
                        @include media-breakpoint-down(lg) {
                            top: 18%
                        }
                    }
                    .precise-info > [class^=col-] {
                        font-size: 18px;
                        line-height: 25px;
                        .title {
                            font-size: 13px;
                            line-height: 19px;
                            padding-bottom: 0.25rem;
                        }
                    }
                }
            }
        }
        .car-body {
            background-color: $white;
            padding-right: 130px;
            @include media-breakpoint-down(lg) {
                padding-right: 100px;
            }
            @include media-breakpoint-down(md) {
                padding-right: 0;
            }
            .checkboxes-wrapper {
                @include media-breakpoint-down(md) {
                    padding-left: 1.875rem !important;
                    padding-right: 1.875rem !important;
                }
                .checkbox-wrapper {
                    padding: 10px;
                    position: relative;
                    .best-price {
                        white-space: nowrap;
                        position: absolute;
                        top: -4px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: $red;
                        border: 1px solid $red;
                        color: $white;
                        border-radius: 20px;
                        padding: 5px 10px;
                        font-size: 14px;
                        line-height: 19px;
                        font-weight: 600;
                    }
                }
                .price-chooser {
                    text-align: center;
                    padding: 1.5rem 0 1.5rem 0;
                    border: 1px solid $grey;
                    cursor: pointer;
                    color: $grey-d2;
                    &:hover, &.active {
                        color: $grey-d2;
                        -webkit-box-shadow:inset 0px 0px 0px 1px $red;
                        -moz-box-shadow:inset 0px 0px 0px 1px $red;
                        box-shadow:inset 0px 0px 0px 1px $red;
                        text-decoration: none;
                    }
                    .price-wrapper {
                        white-space: nowrap;
                        span {
                            @include media-breakpoint-down(lg) {
                                font-size: 12px;
                            }
                            @include media-breakpoint-down(md) {
                                font-size: 16px;
                            }
                        }
                    }
                    @include media-breakpoint-down(sm) {
                        padding: 1rem;
                    }
                }
                .contract-name {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 500;
                }
            }
            .button-wrapper {
                padding-left: 1rem;
                padding-right: 1rem;
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
                .btn-search {
                    line-height: 43px;
                    font-size: 14px;
                    width: 100%;
                    border-radius: unset;
                }
            }    
        }
    }
}

.car-chosen {
    background-color: $white;
    overflow: hidden;
    .sub-car.container {
        @include media-breakpoint-down(md) {
            max-width: 992px;
        }
        @include media-breakpoint-down(sm) {
            max-width: 768px;
        }
    }
    .sub-car {
        .back-btn-search {
            min-height: unset;
            max-width: 100%;
            width: 100%;
            position: relative;
            .nav-text {
                display: none;
            }
            .back-btn{
                .arrow-icon {
                    display: block;
                    position: absolute;
                    left: -34px;
                    top: 56px;
                    @include media-breakpoint-down(md) {
                        left: 8px;
                        top: 58px;
                    }
                    @include media-breakpoint-down(sm) {
                        left: -4px;
                    }
                }
            }
            .btn.back-btn>span {
                display: none;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .sub-car {
            padding-bottom: 0;
            padding-left: 0;
            padding-right: unset;
            padding-top: 0px;
            .back-btn-search {
                padding-left: 1rem;
                .back-btn {
                    padding-left: 1.8rem;
                    position: unset;
                }
            }
        }
    }
}