.my-information {
    padding: 3.75rem 0;
    form {
        label {
            display: block;
            font-weight: 600;
        }
        label[for=receive-information], label[for=avis-offers] {
            font-weight: 400;
            display: inline;
            padding-left: 0.75rem;
        }
        input {
            height: 2.5rem;
            outline: none;
            padding-left: 0.75rem;
            width: 100%;
            border: 1px solid $grey;
            &:disabled{
                background-color: $grey-l1;
            }
        }
        input[type=checkbox] {
            width: unset;
            height: unset;
        }
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
        }
    }
    .card-car {
        background-color: $grey-l2;
        border-color: $grey;
        min-height: 220px;
        .card-body {
            border-width: 0;
            .card-information {
                background-color: unset;
                height: 11.25rem;
                padding: 1.25rem;
                .card-title {
                    font-size: 18px;
                    font-weight: 600;
                }
                img {
                    bottom: -50px;
                    right: 100px;
                    max-width: 50%;
                }
            }
            .red-bottom {
                height: 2.5rem;
                background-color: $red;
            }
        }
    }
}