.header-block{
    padding-bottom: 40px;
    margin-top: 20px;
    background-color: $grey-l2;
    @include media-breakpoint-down(md){
        padding-bottom: 10px;
    }
    &.large-header{
        background-color: transparent;
    }
    .row{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .f-w{
        .row{
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
        .intro{
            max-width: 50% !important;
            position: absolute;
            left: 0;
            z-index: 1;
            margin-left: 150px;
            @include media-breakpoint-down(md){
                max-width: 90% !important;
                margin-left: 20px;
            }
            .pre-title, h1, .text{
                color: $white;
            }
            .pre-title{
                margin-bottom: 120px;
                @include media-breakpoint-down(md){
                    margin-bottom: 80px;
                }
            }
        }   
    }
    .intro{
        margin-top: 60px !important;
        @include media-breakpoint-down(md){
            margin-bottom: 30px;
        }
        .pre-title{
            color: $red;
            font-size: 16px;
            letter-spacing: 1.8px;
            margin-bottom: 34px;
            text-transform: uppercase;
            @include media-breakpoint-down(md){
                font-size: 14px;
            }
        }
        h1{
            font-size: calc(32px + (60 - 32) * ((100vw - 414px) / (1920 - 414)));
            line-height: calc(38px + (60 - 38) * ((100vw - 414px) / (1920 - 414)));
            margin-bottom: 20px;
        }
        .text{
            p{
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                @include media-breakpoint-down(sm){
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
    .standard-header-image{
        &.small-image-container{
            img{
                width: 100%;
                @include media-breakpoint-down(md){
                    min-width: 100vw;
                    max-height: 180px;
                    object-fit: cover;
                }
            }
        }
        &.full-width-image-container{
            img{
                width: 100%;
                max-height: 530px;
                object-fit: cover;
                @include media-breakpoint-down(sm){
                    max-height: 420px;
                }
            }
        }
    }
}