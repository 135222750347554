.single-car {
    .slick-slider {
        .slick-slide {
            width: 600px;
            opacity: 0.5;
            transition: opacity 0.2s ease-out;
            padding: 0 5px;
            &.slick-active {
                opacity: 1;
                transition: opacity 0.2s ease-in;
            }
            .card-wrapper {
                padding:0
            }
        }
    }
}