.search-container {
    min-height: 115px;
    background-color: $white;
    padding-top: 65px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
    .normal-search {
        padding-bottom: 1rem;
    }
    .extensive-search {
        border-top: 1px solid $grey-l1;
        padding-top: 1.75rem;
        padding-right: 7px;
        padding-left: 7px;
        &>div:not(.dropdown-col) {
            padding-right: 7px;
            padding-left: 7px;
            max-width: 105px;
            @include media-breakpoint-down(lg) {
                max-width: 100%;
                .search-btn {
                    text-align: right;
                    padding-right: calc(50% - 7px);
                }
            }
        }
        .dropdown-col {
            padding-left: 8px;
            padding-right: 8px;
        }
        @include media-breakpoint-down(xs) {
            .dropdown-col:last-child {
                padding-bottom: 20px;
            }
        }
        .dropdown-col.active {
            z-index: 1;
        }
        .empty-title {
            height: 1.6rem;
        }
        .auto-title {
            font-size: 12px;	
            font-weight: 500;	
            line-height: 16px;
            padding-bottom: 10px;
            padding-right: 5px;
            .clean-btn {
                display: inline;
                float: right;
                border: 1px solid $red;
                border-radius: 50%;
                background-color: $red;
                width: 14px;
                height: 14px;
                position: relative;
                cursor: pointer;
                span {
                    color: $white;
                    left: 2px;
                    position: absolute;
                    top: -2px;
                }
            }
        }
        .autocomplete-text {
            padding-bottom: 3rem;
            max-height: 70px;
            .input-wrapper{
                .select2-container {
                    width: 100% !important;
                    text-align: left;
                    padding-left: 10px;
                    font-size: 14px;
                    line-height: 19px;
                    height: 37px;
                }
                .select2-results__option {
                    font-size: 14px !important;
                }
                .select2-container--default .select2-selection--single .select2-selection__arrow b {
                    background-size: 13px;
                }
                .select2-container--default .select2-selection--single .select2-selection__rendered {
                    line-height: 37px;
                }
                .select2-container--default .select2-selection--single .select2-selection__arrow {
                    height: 20px;
                }
                @include media-breakpoint-down(lg) {
                    select {
                        display: block;
                        font-size: 16px;
                        font-family: sans-serif;
                        font-weight: 700;
                        color: #444;
                        line-height: 1.3;
                        padding: .6em 1.4em .5em .8em;
                        width: 100%;
                        max-width: 100%;
                        box-sizing: border-box;
                        margin: 0;
                        border: 1px solid #aaa;
                        box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
                        border-radius: .5em;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        appearance: none;
                        background-color: #fff;
                        background-image: url(../img/arrow-down.svg);
                        background-repeat: no-repeat, repeat;
                        background-position: right .7em top 50%, 0 0;
                        background-size: .65em auto, 100%;
                        border-radius: 0;
                    }
                    select::-ms-expand {
                        display: none;
                    }
                    select:hover {
                        border-color: #888;
                    }
                    select:focus {
                        border-color: #aaa;
                        box-shadow: none;
                        box-shadow: 0 0 0 3px -moz-mac-focusring;
                        color: #222;
                        outline: none;
                    }
                    select option {
                        font-weight:normal;
                    }
                }
            }
            input[type=text]{
                width: calc(100% - 25px);
                outline: none;
                border-radius: 4px 0 0 4px;
                border: none;
                border-right: 1px solid #BEBEBE;
                font-size: 14px;
            }
          ul {
              background-color: white;
              list-style: none;
              margin: 0;
              padding: 0;
              text-align: left;
              border: 1px solid $grey-l1;
              border-top: none;
              z-index: 0;
              &::before {
                  content: "";
              }
              li {
                  padding: 0.5rem .5rem;
                  cursor: pointer;
                  &:hover{
                      background-color: $grey-l2;
                  }
                  input[type=checkbox] {
                      position: absolute;
                      opacity: 0;
                      margin-top: 6px;
                      margin-left: 3px;  
                      z-index: 1;
                      &:hover {
                          cursor: pointer;
                      }
                   }
                   input:checked ~ .checkmark {
                    background-color: $white;
                }
                input:checked ~ .checkmark:after {
                    display: block;
                }
                   .checkmark {
                    position: absolute;
                    margin-top: 6px;
                    margin-left: 3px;
                    height: 12px;
                    width: 12px;
                    background-color: $white;
                    border: 1px solid $grey;	
                }
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
                .checkmark:after {
                    left: 3px;
                    top: -1px;
                    width: 5px;
                    height: 9px;
                    border: solid $red;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
                label {
                    margin-left: 1.5rem;

                }
                label:hover {
                    cursor: pointer;
                }
              }
              display: none;
              &.active {
                  display: block;
                  z-index: 1;
              }
          }
        }
        #checkbox1 {
            padding-top: 1.5rem;
            label{
                padding-left: 3rem;
                display: inline !important;
                width: 100%;
                font-size: 1.25rem;
                line-height: 2.25rem;
                font-weight: 600;
            }
            input[type=checkbox] {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                margin-top: 7px;
                left: 1.5rem;
                height: 1.5rem;
                width: 1.5rem;
                z-index: 1;
                &:checked {
                    font-weight: 600;
                    color: $grey-l2;
                }
            }
            input:checked ~ #checkmark1  {
                background-color: $white;
            }
            input:checked ~ #checkmark1:after {
                display: block;
            }
            #checkmark1  {
                position: absolute;
                margin-top: 7px;
                left: 1.5rem;
                height: 1.5rem;
                width: 1.5rem;
                background-color: $white;
                border: 1px solid $grey;
            }
            #checkmark1:after {
                content: "";
                position: absolute;
                display: none;
            }
            #checkmark1:after {
                left: 6px;
                width: 10px;
                height: 1rem;
                border: solid $red;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }   
        } 
        .extras {
            padding-right: 1rem;
            position: relative;
            margin: 0 0.5rem 0.5rem 0;
            &.btn-primary:not(:disabled):not(.disabled).active.active {
                &:before {
                    transform: rotate(45deg);
                }
            }
            &:before{
                position: absolute;
                content: "+";
                left: 14px;
                font-size: 16px;
                margin-top: -3px;
                transition: transform 0.3s linear; 
            }
        }
        .search-btn {
            background-color: $red;
            padding: 8px 0 8px 10px;
            padding-right: 0;
            font-size: 14px;
            color: $white;
            font-weight: 500;
            border-radius: 3px;
            position: relative;
            cursor: pointer;
        }
        .search-btn:after {
            position: absolute;
            top: 11px;
            content: url(../img/arrow-next-white.svg);
            margin-left: 10px;
        }
    }
    .car-type-wrapper {
        @include media-breakpoint-down(sm) {
            padding-bottom: 1.5rem;
            padding-right: 0;
            padding-left: 0;
        }
        .car-type {
            position: relative;
            color: $black;
            margin-right: 10px;
            font-size: 18px;
            font-weight: 500;
            line-height: 25px;
            padding-right: 1rem;
            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
            }
            @include media-breakpoint-down(sm) {
                margin-right: 5px;
            }
            &:last-child {
                margin-right: unset;
            }
            &.active {
                color: $red;
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
    .rent-wrapper {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
        }
    }

}
.search-button {
    text-align: right;
    @include media-breakpoint-down(sm) {
        text-align: left!important;
        margin-bottom: 24px;
    }
    .btn.btn-primary {
        background-color: $red;
        color: $white;
        border: solid 1px $red;
        // border: solid 1px rgba($black, .5);
        //opacity: 0.5;
        padding-right: 1.5rem;
        line-height: 1.5rem;
        @include media-breakpoint-down(md) {
            padding: 0.4rem 0.75rem 0.4rem 1rem;
        }
        &:hover {
            border: solid 1px rgba($red, 1);
            text-decoration: underline;
            
        }
        &.btn-primary:not(:disabled) {
            &:not(.disabled) {
                &:active {
                    background-color: $red;
                    color: $white;
                    border-color: $red;
                    box-shadow: none;
                }
            }
        }
        
        svg.search-icon {
            margin-left: 1.25rem;
            margin-top: 2px;
            transform: scaleX(-1);
            path {
                fill: $white;
            }
        }
        .close {
            font-weight: 500;
            font-size: 1.5rem;
            margin-left: 1rem;
            color: $white;
        }
    }
}
.car-slider {
    .slick-slider {
        .slick-prev, .slick-next {
            width: 40px;
            height: 40px;
            border: unset;
            background: $white;
            border-radius: 50%;
            box-shadow: 2px 4px 5px rgba(210, 7, 47, 0.05);
        }
        .slick-next:before, .slick-prev:before {
            content: none
        }
    }
}

ul[aria-multiselectable="true"] {
    .select2-results__option:before {
        content: "";
        display: inline-block;
        position: relative;
        height: 13px;
        width: 13px;
        border: 2px solid #e9e9e9;
        border-radius: 0;
        background-color: $white;
        margin-right: 10px;
        vertical-align: middle;
      }
      .select2-results__option[aria-selected=true]:before {
        content: "\2713";
        color: $white;
        background-color: $red;
        border: 0;
        display: inline-block;
        padding-left: 2px;
        font-size: 12px;
      }
  }
  .select2-results__option {
      font-size: 14px;
  }
  .multiselect {
    .select2-container--default.select2-container--focus .select2-selection--multiple, .select2-container--default .select2-selection--multiple {
        border: none;
        height: 100%;
        .select2-selection__rendered {
            display: block;
            border: none;
            height: 100%;
        }
    }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
        padding: 0;
    }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered li:hover {
        background-color: $white;
    }
    .select2-results__message {
        display: none !important;
      }
    .select2-container--default.select2-container--focus .select2-selection--multiple .select2-selection__rendered, .select2-container--default .select2-selection--multiple .select2-selection__rendered {
        padding-top: 0.5rem;
        padding-left: 0.125rem;
        &:after{
            content: '+';
            color: $black;
            font-size: 1.35rem;
            padding-left: 0.75rem;
            position: absolute;
            font-weight: 400;
            transition: transform 0.3s linear;
            padding-top: 1px;
            right: 0.75rem;
            border-left: 1px solid #bebebe;
            cursor: pointer;
            top: 8px;
        }
      }
      .select2-container--default.select2-container--focus.select2-container--open .select2-selection--multiple .select2-selection__rendered, .select2-container--default.select2-container--open .select2-selection--multiple .select2-selection__rendered {
          &:after {
            content: url(../img/arrow-up.svg);
            width: 1.65rem;
          }
      }
      
  }