.card-wrapper {
    padding: 15px;
    margin-bottom: 12px;
    @include media-breakpoint-down(sm) {
        padding: 10px;
        margin-bottom: 8px;
    }
    .card-car {
        min-height: 243px;
        text-decoration: none;
        color: $black;
        overflow: hidden;
        &.card {
            border-radius: 1.25rem !important;
            border: none;
        }
        .card-body{
            border-radius: 1.25rem;
            border: 3px solid $white;
            box-sizing: border-box;
            padding: 0;
        }
    }
}
.card-information {
    background-color: $grey-l2;
    padding: 15px;
    padding-bottom: 50px;
    position: relative;
    border-radius: 17px 17px 0 0 ;
    .card-title{
        .group {
            font-size: 12px;
            color: $red;
            font-weight: 500;
        }
    }
    h6 {
        font-weight: 300;
    }
    .precise-info {
        border-top: 1px solid rgba(69, 69, 69, .2);
        margin: unset;
    }
    .precise-info > [class^=col-] {
        padding-top: 4px;
        font-size: 12px;
        border-right: 1px solid rgba(69, 69, 69, .2);
        .title {
            font-size: 10px;
            color: $grey-d2;
            font-weight: 700;
            padding-top: 0.32rem;
            padding-bottom: 0;
        }
        &:first-child{
            padding-left: 0;
        }
        &:last-child{
            padding-right: 0;
            border-right: none;
        }
    }
    img {
        mix-blend-mode: multiply;
        position: absolute;
        bottom: -100px;
        right: 35px;
        max-width: 80%;
        @include media-breakpoint-down(md) {
            right: 35px;
            max-width: 80%;
            bottom: unset;
            top: 90px;

        }
        &.other-cars {
            max-width: 85%;
            top: 57%;
            left: 7%;
        }
    }
}
.card-bottom {
    .btn-primary {
        position: absolute;
        bottom: 20px;
        left: 33%;
        @include media-breakpoint-down(md) {
            left: 40%;
        }
        @include media-breakpoint-down(sm) {
            left: 33%;
        }
    }
}