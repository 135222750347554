[id^=CarDetailModal] {
    .modal-dialog {
        max-width: 840px;
    }
    .modal-content {
        flex-direction: row;
    }
    .car-information {
        -webkit-box-shadow: 5px 0 5px -2px $grey;
        box-shadow: 5px 0 5px -2px $grey;
        .modal-header {
            padding: 0;
            background-color: $grey-l2;
            .card-information {
                width: 100%;
                padding-top: 20px;
                padding-left: 30px;
                padding-right: 30px;
                .close {
                    margin: unset;
                    top: 0;
                    position: absolute;
                    right: 0;
                    font-size: 1rem;
                    color: $red;
                    font-weight: 600;
                    outline: none; 
                    span:focus {
                        outline:none;
                    }
                    span:first-child{
                        border: 1px solid $white;
                        border-radius: 50%;
                        padding: 0 7px 0 7px;
                        background-color: $white;
                    }
                    span:last-child{
                        text-transform: uppercase;
                        font-size: .75rem;
                    }
                }
                .card-img {
                    max-width: 60%;
                    z-index: 1;
                    left: 20%;
                    top: 48%;
                }
            }
            .lower-card-part {
                height: 70px;
                background-color: $red;
            }
            .circle:before {
                content: '\25CF';
                font-size: 2rem;
                line-height: 1rem;
                margin-left: -2px;
            }
            .circle {
                &.gray {
                    color: gray;
                }
                &.brown {
                    color: brown;
                }
                &.blue {
                    color: blue;
                }
                &.green {
                    color: green;
                }
                &.white {
                    color: white;
                }
                &.silver {
                    color: silver;
                }
                &.black {
                    color: black;
                }
                &.yellow {
                    color: yellow;
                }
                &.gold {
                    color: gold;
                }
            }
        }
        .modal-body {
            height: 445px;
            &.scrollable {
                overflow-x: scroll;
            }
            .checkboxes-wrapper {
                height: 220px;
                .price-checkbox-wrapper1 {
                    position: relative;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 90px;
                        width: 210px;
                        z-index: 1;
                        &:checked {
                            font-weight: 600;
                            color: $grey-d2;
                        }
                    }
                    input:checked ~ #price-checkmark1 {
                        background-color: $white;
                        border: 2px solid $red;
                    }
                    input:checked ~ #price-checkmark1:after {
                        display: block;
                    }
                    #price-checkmark1 {
                        padding: 1.25rem;
                        text-align: center;
                        position: absolute;
                        height: 90px;
                        width: 210px;
                        background-color: $white;
                        border: 1px solid $grey;	
                        border-radius: 2px;
                    }
                    #price-checkmark1:after {
                        content: "";
                        position: absolute;
                        display: none;
                        left: 16px;
                        bottom: 14px;
                        width: 4px;
                        height: 8px;
                        border: solid $red;
                        border-width: 0 2px 2px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    #price-round1 {
                        position: absolute;
                        height: 90px;
                        width: 210px;
                    }
                    #price-round1:after {
                        content: "";
                        position: absolute;
                        display: none;
                        width: 20px;
                        height: 20px;
                        border: 1px solid $red;
                        border-radius: 50%;
                        bottom: 10px;
                        left: 10px;
                    }
                    input:checked ~ #price-round1:after {
                        display: block;
                    }
                }
                .price-checkbox-wrapper2 {
                    position: relative;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 90px;
                        width: 210px;
                        z-index: 1;
                        &:checked {
                            font-weight: 600;
                            color: $grey-d2;
                        }
                    }
                    input:checked ~ #price-checkmark2 {
                        background-color: $white;
                        border: 2px solid $red;
                    }
                    input:checked ~ #price-checkmark2:after {
                        display: block;
                    }
                    #price-checkmark2 {
                        padding: 1.25rem;
                        text-align: center;
                        position: absolute;
                        height: 90px;
                        width: 210px;
                        background-color: $white;
                        border: 1px solid $grey;	
                        border-radius: 2px;
                    }
                    #price-checkmark2:after {
                        content: "";
                        position: absolute;
                        display: none;
                        left: 16px;
                        bottom: 14px;
                        width: 4px;
                        height: 8px;
                        border: solid $red;
                        border-width: 0 2px 2px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    #price-round2 {
                        position: absolute;
                        height: 90px;
                        width: 210px;
                    }
                    #price-round2:after {
                        content: "";
                        position: absolute;
                        display: none;
                        width: 20px;
                        height: 20px;
                        border: 1px solid $red;
                        border-radius: 50%;
                        bottom: 10px;
                        left: 10px;
                    }
                    input:checked ~ #price-round2:after {
                        display: block;
                    }
                }
                .price-checkbox-wrapper3 {
                    position: relative;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 90px;
                        width: 210px;
                        z-index: 1;
                        &:checked {
                            font-weight: 600;
                            color: $grey-d2;
                        }
                    }
                    input:checked ~ #price-checkmark3 {
                        background-color: $white;
                        border: 2px solid $red;
                    }
                    input:checked ~ #price-checkmark3:after {
                        display: block;
                    }
                    #price-checkmark3 {
                        padding: 1.25rem;
                        text-align: center;
                        position: absolute;
                        height: 90px;
                        width: 210px;
                        background-color: $white;
                        border: 1px solid $grey;	
                        border-radius: 2px;
                    }
                    #price-checkmark3:after {
                        content: "";
                        position: absolute;
                        display: none;
                        left: 16px;
                        bottom: 14px;
                        width: 4px;
                        height: 8px;
                        border: solid $red;
                        border-width: 0 2px 2px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    #price-round3 {
                        position: absolute;
                        height: 90px;
                        width: 210px;
                    }
                    #price-round3:after {
                        content: "";
                        position: absolute;
                        display: none;
                        width: 20px;
                        height: 20px;
                        border: 1px solid $red;
                        border-radius: 50%;
                        bottom: 10px;
                        left: 10px;
                    }
                    input:checked ~ #price-round3:after {
                        display: block;
                    }
                }
                .price-checkbox-wrapper4 {
                    position: relative;
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 90px;
                        width: 210px;
                        z-index: 1;
                        &:checked {
                            font-weight: 600;
                            color: $grey-d2;
                        }
                    }
                    input:checked ~ #price-checkmark4 {
                        background-color: $white;
                        border: 2px solid $red;
                    }
                    input:checked ~ #price-checkmark4:after {
                        display: block;
                    }
                    #price-checkmark4 {
                        padding: 1.25rem;
                        text-align: center;
                        position: absolute;
                        height: 90px;
                        width: 210px;
                        background-color: $white;
                        border: 1px solid $grey;	
                        border-radius: 2px;
                    }
                    #price-checkmark4:after {
                        content: "";
                        position: absolute;
                        display: none;
                        left: 16px;
                        bottom: 14px;
                        width: 4px;
                        height: 8px;
                        border: solid $red;
                        border-width: 0 2px 2px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    #price-round4 {
                        position: absolute;
                        height: 90px;
                        width: 210px;
                    }
                    #price-round4:after {
                        content: "";
                        position: absolute;
                        display: none;
                        width: 20px;
                        height: 20px;
                        border: 1px solid $red;
                        border-radius: 50%;
                        bottom: 10px;
                        left: 10px;
                    }
                    input:checked ~ #price-round4:after {
                        display: block;
                    }
                }
            }
            .detailed-car-information {
                padding-top: 1rem;
                .title {
                    padding: 1rem;
                    &.show-extras {
                        text-align: right;
                        font-size: .75rem;
                        color: $red;
                        font-weight: 600;
                        &::before {
                            content: "+"
                        }
                        cursor: pointer;
                    }
                    &.show-less {
                        &::before {
                            display: inline-block;
                            transform: rotate(45deg);
                            transition: 0.5s;
                        }

                    }
                }
                .extra {
                    text-align: center;
                    border: 0.5px solid $grey-l2;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                    font-size: 0.75rem;
                }
            }

        }
    }
    .other-car-information {
        height: 715px;
        overflow-y: scroll;
        background-color: $grey-l2;
        box-shadow: inset 9px 0 9px -7px rgba(0,0,0,0.4);
        -webkit-box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.4);
        .modal-body-title {
            position: fixed;
            padding: 1rem;
            width: 350px;
            z-index: 1;
            background: linear-gradient(180deg, $grey-l2 0%, rgba(242, 242, 244, 0) 100%);
            height: 6rem;
        }
        .card-wrapper {
            padding: 0.5rem;
            .card {
                border: none;
            }
        }
        .card-wrapper:first-child{
            padding-top: 2.5rem;
        }
    }
}