.homepage {
    min-height: 75vh !important;
}
.header-image{
    height: 75vh;
    max-height: 566px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: relative;
    &:after{
        content: '';
        position: absolute;
        width: 36vw;
        height: 100%;
        left: 0px;
        top: 0px;

        background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        mix-blend-mode: multiply;
        opacity: 0.5;
    }
    @media (min-width: 1440px) {
        .header-container {
            max-width: calc(1122px + (100vw - 1122px)/2);
        }
    }
    .header-information {
        position: absolute;
        top: 15%;
        left: 5%;
        background-color: transparent;
        color: $white;
        z-index: 1;
        @include media-breakpoint-down(xs) {
            top: 3%;
        }
        .title {
            font-size: 48px;
            line-height: 60px;
            font-weight: 500;
            color: $white;
            text-shadow: 1px 2px 0 rgba(0,0,0,0.5);
            max-width: 60%;
            @include media-breakpoint-down(sm) {
                max-width: 100%;
            }
            @include media-breakpoint-down(xs){
                font-size: 36px;
                line-height: 48px;
            }
        }
        .subtitle {
            padding-top: 12px;
            padding-bottom: 2rem;
            font-weight: 400;
            font-size: 28.8px;
            line-height: 44px;
            text-shadow: 1px 2px 0 rgba(0,0,0,0.5);
            @include media-breakpoint-down(xs){
                font-size: 20px;
                line-height: 36px; 
            }
        }
        .pros_text {
            font-weight: 500;
            text-shadow: 1px 2px 0 rgba(0,0,0,0.5);
            ul {
                padding-left: 0;
                li {
                    list-style-type: none;
                    padding-left: 1.5rem;
                    position: relative;
                    padding-bottom: 0.65rem;
                }
                li:before {
                    content: '';
                    display: block;
                    width: 8px;
                    height: 13px;
                    border: solid #ffffff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    position: absolute;
                    left: 5px;
                    top: 2px;
                }
            }

        }
        .btn.btn-primary {
            background-color: transparent;
            border-color: $white;
            padding: 0.6rem 2rem 0.6rem 1rem;
        }
        .btn.btn-caps:after {
            top: 14px;
        }
    }

    .car-chooser-popup {
        position: absolute;
        bottom: -30px;
        transform: translateX(-50%);
        left: 50%;
        background-color: $red;
        border-radius: 45px;
        padding: 15px 25px;
        cursor: pointer;
        width: 270px;
        text-align: center;
        z-index: 4;
        .title {
            color: $white;
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 500;
        }
    }
}
.car-chooser {
    position: absolute;
    width: 414px;
    right: 0;
    bottom: 0;
    min-height: 523px;
    background-color: $red;
    border-radius: 20px 20px 0 0;
    padding: 35px 30px 24px 30px;
    z-index: 5;
    .title {
        color: $white;
        font-size: 24px;
        font-weight: 500;
        padding-left: 8px;
        padding-bottom: 5px;
    }
    .wrapper {
        padding: 12.5px 15px;
        a:hover {
            color: $black;
            text-decoration: none;
            .chooser {
                .car-name {
                    text-decoration:none;
                }
            }
        }
        .chooser {
            background-color: $white;
            padding: 30px 20px 28px 20px;
            border-radius: 20px;
            z-index: 2;
            height: 160px;
            cursor: pointer;
            border-bottom: 50px solid #EFEFEF;
            @include media-breakpoint-down(xs) {
                height: 140px;
            }
            .car-name {
                text-align: center;
                color: $black;
                font-size: 16px;
                line-height: 22px;
                font-weight: 500;
            }
            .card-img {
                mix-blend-mode: multiply;
            }
        }
        &.offer {
            .chooser {
                background: linear-gradient(223.78deg, #494848 0%, #282626 100%);
                box-shadow: 0 0 10px 0 rgba(0,0,0,0.25);
                border-bottom: unset;
                padding: 30px 0 !important;
                .car-name {
                    text-align: center;
                    font-weight: 600;
                    letter-spacing: 0;
                    text-transform: uppercase;
                    margin: auto;
                    .type {
                        max-width: 110px;
                        text-align: center;
                        margin: auto;
                    }
                    .type:first-child {
                        color: $yellow;
                        font-size: 24px;
                        line-height: 33px;
                        border-top: 1px solid $white;
                    }
                    .type:last-child {
                        color: $white;
                        font-size: 37px;
                        line-height: 52px;
                        border-bottom: 2px solid $white;
                    }
                    &.en {
                        .type {
                            max-width: 142px;
                        }
                    }
                    &.pl {
                        .type {
                            max-width: 145px;
                        }
                        .type:first-child {
                            font-size: 20px;
                        }
                        .type:last-child {
                            font-size: 24px;
                        }
                    }
                    &.lt {
                        .type {
                            max-width: 145px;
                        }
                        .type:first-child {
                            font-size: 18px;
                        }
                        .type:last-child {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
    .btn-wrapper {
        padding-top: 10px;
    }
    .btn.btn-primary {
        border-color: $white;
        padding: 0.6rem 1.5rem;
        font-weight: 500;
        &.detailed-search {
            cursor: pointer;
        }
        svg {
            path {
                fill: $white;
            }
        }
        .search-icon {
            transform: scale(-1, 1);
            margin-left: 10px;
            margin-bottom: 2px;
        }
    }
    &.search-container {
        width: 480px;
        height: 100%;
        border-radius: 0;
        background-color: $grey-l2;
        padding-left: 0;
        padding-right: 0;
        padding-top: 25px;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        .extensive-search {
            border-top: unset;
            margin: 0;
            padding-left: 20px;
            padding-right: 20px;
            // min-height: calc(100% - 125px);
            .title {
                font-size: 24px;
                font-weight: 500;
                line-height: 33px;
                color: $black;
                padding-left: 15px;
            }
            .close {
                font-size: 2rem;
                color: $black;
                float:right;
                font-weight: 500;
                position: absolute;
                right: 2rem;
                top: 2rem;
                border-radius: 20px;
                background-color: $white;
                padding: 3px 8px;
                outline: none;
            }
        }
        .search-btn {
            position: absolute;
            bottom: 0;
            background-color: $red;
            height: 50px;
            width: 100%;
            font-size: 18px;
            line-height: 50px;
            font-weight: 500;
            color: $white;
            text-align: center;
            cursor: pointer;
            @include media-breakpoint-down(md){
                height: 90px;
                bottom: 59px;
                line-height: 90px;
            }
            &.disabled {
                background-color: $grey-l1;
                cursor: not-allowed;
                pointer-events: none;
                span {
                    display: none;
                }
            }
            &:hover {
                text-decoration: none;
            }
        }
        .search-btn:after {
            content: url(../img/arrow-next-white.svg);
            margin-left: 20px;
        }
    }
}
#FindACar {
    &.modal {
        top: 414px;
    }
    &.show {
        top: 0;
        transition: top 0.5s linear;
    }
    .modal-bottom {
        bottom: 0;
        position: absolute;
        margin: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
    }
    .modal-content {
        border-radius: 20px 20px 0 0;
        max-width: 414px;
        margin: auto;
        background-color: transparent;
        border: transparent;
        // @include media-breakpoint-down(sm) {
        //     max-width: 100%;
        // }
    }
    .car-chooser {
        @media (max-width: 414px) {
            width: 100%;
            max-width: 100%;
        }
    }
}
#FindACarChosenCar {
    .modal {
        top: -414px;
    }
    .modal-bottom {
        top: 56px;
    }
    .modal-content {
        border-radius: 0 0 20px 20px;
        min-height: 474px;
        border-color: transparent;
        background-color: transparent;
        margin:auto;
        width: 414px;
    }
    .car-chooser {
        border-radius: 0 0 20px 20px;
    }
    @include media-breakpoint-down(md) {
        min-height: 427px;
    }
    @include media-breakpoint-down(sm) {
        .modal-content {
            width: 374px;
            min-height: 430px;
            .car-chooser {
                width: 374px;
                min-height: 450px;
                right: unset !important;
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .modal-content {
            min-height: 445px;
        }
    }
}

#DetailedSearch {
    &.modal {
        top: 0;
        overflow: hidden;
    }
    .modal-header {
        background-color: $red;
        height: 60px; 
        border: none;
    }
    .modal-dialog {
        margin: 0;
        height: 100%;
        max-width: 100%;
        .modal-content {
            height: 100%;
            border: none;
            .modal-body.search-container {
                height: calc(100% - 150px);
                top: 0;
                overflow: scroll;
            }
            .search-btn {
                position: absolute;
                bottom: 0;
                background-color: $red;
                height: 50px;
                width: 100%;
                font-size: 18px;
                line-height: 50px;
                font-weight: 500;
                color: $white;
                text-align: center;
                cursor: pointer;
                @include media-breakpoint-down(md){
                    height: 90px;
                    bottom: 59px;
                    line-height: 90px;
                }
                &.disabled {
                    background-color: $grey-l1;
                    cursor: not-allowed;
                    pointer-events: none;
                    span {
                        display: none;
                    }
                }
                &:hover {
                    text-decoration: none;
                }
            }
            .search-btn:after {
                content: url(../img/arrow-next-white.svg);
                margin-left: 20px;
            }
        }
    }
}
.car-slider {
    @include media-breakpoint-up(xl) {
        max-width: 1090px;
    }
    @include media-breakpoint-down(md) {
        max-width: 724px;
    }
    @include media-breakpoint-down(sm) {
        max-width: calc(100% - 100px);
    }
    @include media-breakpoint-down(sm) {
        max-width: calc(100% - 20px);
        padding-right: 0px;
        padding-left: 0px;
        margin: 0 auto;
    }
    .slick-track {
        width: 10224px!important;
    }
    .slick-slider {
        padding-bottom: 80px;
        @include media-breakpoint-down(lg) {
            padding: 0px 20px 80px;
            &:after {
                content: "";
                width: 127px;
                background-image: linear-gradient(to left, rgba($grey-l2, 1), rgba($grey-l2, 0));
                display: block;
                position: absolute;
                top: 0;
                right: 20px;
                height: calc(100% - 64px);
            }
        }
        @media (max-width: 400px) {
            &:after {
                content: none;
            }
        }
        .slick-slide {
            width: 354px;
            height: 258px;
            .card-wrapper {
                //width: 334px;
                @media (max-width: 400px) {
                    width: calc(100vw - 60px);
                    .card-car {
                        width: 100%;
                        .card-body {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .slick-prev, .slick-next {
            width: 40px;
            height: 40px;
            border: unset;
            background: $white;
            border-radius: 50%;
            box-shadow: 2px 4px 5px rgba(210, 7, 47, 0.05);
            z-index: 1;
            top: 42%;
        }
        .slick-next:before, .slick-prev:before {
            content: none
        }
        .slick-prev {
            left: -5%;
            @include media-breakpoint-down(sm) {
                left: 0%;
            }
        }
        .slick-next{
            right: -5%;
            @include media-breakpoint-down(sm) {
                right: 0%;
            }
        }
        .slick-disabled {
            display: none !important;
        }
        .slick-list.no-left-padding {
            padding-left: 0 !important;
        }
    }
}
.car-detail-title {
    padding-top: 60px;
    color: $black;
    font-size: 28px;
    line-height: 38px;
    font-weight: 500;
    padding-left: 34px;    
    padding-bottom: 5px;
    margin-bottom: 0; 
}