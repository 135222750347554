.intro {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1122px;
    margin-top: 160px;
    margin-bottom: 60px;
    @include media-breakpoint-down(lg) {
        max-width: 960px;
    }
    @include media-breakpoint-down(md) {
        max-width: 720px;
        margin-top: 80px;
    }
    @include media-breakpoint-down(sm) {
        max-width: 540px;
    }
    @include media-breakpoint-down(xs) {
        margin-top: 40px;
    }
    div {
        &:nth-child(1) {
            font-size: 48px;
            font-weight: 500;
            line-height: 60px;
            margin-bottom: 20px;
            @include media-breakpoint-down(md) {
                font-size: 38px;
                line-height: 50px;
                margin-bottom: 10px;
            }
        }
    }
}
.paragraph-block {
    margin-bottom: 80px;
    .rich-text {
        // h3 {}
        // h4 {
        //     padding-top: 40px;
        // }
        // ul {
        //     padding-left: 72px;
        //     li {
        //         margin-top: 24px;
        //     }
        // } 
        ol {
            list-style-type: none;
            counter-reset: item;
            margin: 0;
            padding: 0;
            // margin-left: -24px;
            li {
                display: table;
                counter-increment: item;
                margin-bottom: 0.6em;
                margin-top: 40px;
                font-weight: 500;
                position: relative;
                padding-left: 24px;
                &:before {
                    content: counters(item, ".") ". ";
                    display: table-cell;
                    padding-right: 0.6em;
                    position: absolute;
                    left: 0px;
                }
                ol {
                    margin-left: -24px;
                    li {
                        margin: 0;
                        margin-top: 24px;
                        font-weight: 300;
                        padding-left: 56px;
                        &:before {
                            content: counters(item, ".") " ";
                        }
                        ol {
                            margin-left: -56px;
                        }
                    }
                }
            }
        }
    }
}