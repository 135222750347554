.title-starfsfolk {
    .intro {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
        margin-right: auto;
        margin-left: auto;
        max-width: 1122px;
        margin-top: 160px;
        margin-bottom: 60px;
        @include media-breakpoint-down(lg) {
            max-width: 960px;
        }
        @include media-breakpoint-down(md) {
            max-width: 720px;
            margin-top: 80px;
        }
        @include media-breakpoint-down(sm) {
            max-width: 540px;
        }
        @include media-breakpoint-down(xs) {
            margin-top: 40px;
        }
        div {
            &:nth-child(1) {
                font-size: 48px;
                font-weight: 500;
                line-height: 60px;
                margin-bottom: 20px;
                @include media-breakpoint-down(md) {
                    font-size: 38px;
                    line-height: 50px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .staffmember-block {
        margin-bottom: 120px;
        .staffmember-list {
            .staffmember {
                padding: 16px 0px;
                font-size: 14px;
                height: fit-content;
                border-bottom: 1px solid #bebebe;
                &:hover {
                    background-color: $white;
                    div {
                        &:nth-child(3) {
                            color: $red;
                        } 
                    }
                }
                &:nth-child(1) {
                    border-top: 1px solid #bebebe;
                }
                div {
                    @include media-breakpoint-down(md) {
                        &:nth-child(3) {
                            padding-top: 12px;
                        }
                    }
                    &:nth-child(1) {
                        font-weight: 500;
                    } 
                    &:nth-child(3) {
                        text-align: right;
                        @include media-breakpoint-down(md) {
                            text-align: left;
                        }
                    } 
                }
            }
        }
    }
}