.order-car {
    max-width: 1440px;
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
    }
    .choosing-steps {
        background-color: $grey-l2;
        min-height: calc(100vh - 216px);
        @include media-breakpoint-down(md) {
            min-height: unset;
        }
        &.step5 {
            padding: 1.75rem;
            padding-right: 3rem;
            .chooser-container {
                padding-left: 0;
                padding-right: 8rem;

                @include media-breakpoint-down(lg) {
                    padding-right: 5rem;
                }
                @include media-breakpoint-down(sm) {
                    padding-right: 1rem;
                    padding-left: 1rem;
                }
                .pickup {
                    border: 1px solid $red;
                    padding: 1.5rem;
                    .title {
                        font-size: 20px;
                        line-height: 27px;
                        font-weight: 600;
                    }
                }
                .early-pickup-wrapper {
                    padding-top: 1rem;
                    .checkmark {
                        border-radius: 0;
                    }
                }
            }
        }
    }
    .selected-car {
        padding: 1.75rem;
        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }
        &.step5 {
            padding: 2.5rem 15% 10% 10%;
            .receipt-title {
                font-size: 24px;
                line-height: 33px;
                font-weight: 600;
                padding-bottom: 1.5rem;
            }
        }
        .subscription-info {
            font-size: 18px;
            line-height: 25px;
            font-weight: 600;
            padding-top: 1rem;
            padding-bottom: 2rem;
            border-top: 1px solid $grey-l1;
            .price-info {
                text-align: right;
                span {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                }
            }
        }
        .login-information {
            padding-top: 1rem;
            padding-bottom: 2.5rem;
            font-size: 18px;
            line-height: 25px;
            border-top: 1px solid $grey-l1;
            .bold-text {
                font-weight: 500;
                padding-top: 10px;
            }
            .title {
                font-size: 14px;
                line-height: 19px;
            }
            .phone-email {
                padding-top: 34px;
            }

        }
        .package-info{
            border-top: 1px solid $grey-l1;
            padding-top: 1rem;
            padding-bottom: 1rem;
            .title {
                font-size: 18px;
                line-height: 19px;
                padding-bottom: 0.5rem;
                font-weight: 500;
            }
        } 
        .chosen-car-info {
            background-color: $white; 
            color: $lighter_black;
            padding-bottom: 1.75rem;
            border-bottom: 1px solid $grey-l1;
            img {
                position: absolute;
                bottom: -36px;
                right: 13px;
                width: 170px;
            }
            .close {
                color: $red;
                position: absolute;
                right: 0;
                top: -20px;
                font-size: 2rem;
                &:hover {
                    cursor: pointer;
                }
            }
            .car-name{
                font-size: 18px;
                line-height: 25px;
                font-weight: 500;
                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                    line-height: 22px;
                }
                .contract-type {
                    font-size: 14px;
                    line-height: 19px;
                    font-weight: 400;
                    @include media-breakpoint-down(sm) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }
        .receipt-info {
            padding-top: 1rem;
            padding-bottom: 2.5rem;
            font-size: 18px;
            line-height: 25px;
            border-top: 1px solid $grey-l1;
            border-bottom: 1px solid $grey-l1;
            .title {
                font-size: 14px;
                line-height: 19px;
            }
            .bold-text {
                font-weight: 500;
                .icon-wrapper {
                    display: inline;
                    span {
                        padding-right: 5px;
                    }
                }
            }
            .icon-wrapper svg.card-icon {
                width: 38px;
                padding-bottom: 5px;
            }

        }
    }
    .container {
        max-width: 1280px;
    }
    .chooser-container {
        padding: 2.5rem 10% 0 15%;
        @include media-breakpoint-down(lg) {
            padding-left: 6%;
            padding-right: 11%;
        }
        @include media-breakpoint-down(md) {
            padding-right: 6%;
            height: auto;
            padding-left: 10px; 
            padding-right: 10px; 
        }
        .credit-info, .payment-step, .early-pickup-wrapper {
            .title {
                margin-bottom: 8px;
            }
            input[type=checkbox] {
                position: absolute;
                opacity: 0;
                margin-top: 1px;
                margin-left: 3px;
                height: 22px;
                width: 22px;
                z-index: 1;
                &:hover {
                    cursor: pointer;
                }
            }
            input:checked ~ .checkmark {
              background-color: $red;
            }
            input:checked ~ .checkmark:after {
                display: block;
            }
            input:checked ~ label {
                font-weight: 600;
            }
            .checkmark {
                position: absolute;
                margin-top: 1px;
                margin-left: 3px;
                height: 22px;
                width: 22px;
                background-color: $white;
                border: 1px solid $red;	
                border-radius: 2px;
            }
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }
            .checkmark:after {
                left: 6.5px;
                top: 1px;
                width: 7px;
                height: 12px;
                border: solid $white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            input[type=checkbox] ~ label {
                margin-left: 2.5rem;
            }            
        }
        .credit-info {
            h2, div {
                padding-left: 0px;
            }
        }
        .checkboxes-wrapper {
            padding-bottom: 30px;
            .price-checkbox-wrapper {
                background-color: $white;
                border-radius: 12px;
                margin-top: 30px;
                padding-right: 20px;
                cursor: pointer;
                &:hover {
                    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
                }
                .subscription-name {
                    border-radius: 8px 0 0 8px;
                    text-transform: uppercase;
                    text-align: center;
                    .subscription-wrapper {
                        color: $white;
                        text-shadow: 0 1px 1px rgba(114,95,0,0.5);
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        @include media-breakpoint-down(md) {
                            left: 50% !important;
                            transform: translateX(-50%) rotate(-90deg);
                            top:40%;
                        }
                        h4 {
                            margin-bottom: 0;
                        }
                        div {
                            font-size: 12px;
                            line-height: 16px;
                            @include media-breakpoint-down(md) {
                                display: none;
                            }
                        }
                    }
                }
                .subscription-info {
                    padding: 2rem;
                    font-size: 14px;
                    div>span {
                        font-weight: 600;
                    }
                }
                .price {
                    font-size: 22px;
                    white-space: nowrap;
                    position: absolute;
                    bottom: 28px;
                    right: 20px;
                    font-weight: 600;
                    @include media-breakpoint-down(md) {
                        padding-top: 1rem;
                        position: unset;
                        bottom: unset;
                        right: unset;
                    }
                    span {
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
                &.silver {
                    .subscription-name {                        
                        background: linear-gradient(180deg, #A6A6A6 0%, #8C8C8C 100%);
                        .subscription-wrapper {
                            left: 24%;
                        }
                    }
                }
                &.gold {
                    .subscription-name {                        
                        background: linear-gradient(180deg, #E4CF3F 0%, #DDB727 100%);
                        .subscription-wrapper {
                            left: 30%;
                        }
                    }
                }
                &.platinum {
                    .subscription-name {                        
                        background: linear-gradient(180deg, #7B776D 0%, #5E5950 100%);
                        .subscription-wrapper {
                            left: 15%;
                            @include media-breakpoint-down(lg) {
                                left: 9%;
                            }
                        }
                    }
                }
            }
        }
        .additionals {
            padding: 1.25rem;
            text-align: center;
            height: 300px;
            max-width: 380px;
            background-color: $white;
            border: 1px solid $grey;	
            border-radius: 2px;
            margin-right: 1rem;
            margin-left: 1rem;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .button-wrapper {
            padding-top: 5.75rem;
            padding-bottom: 5.75rem;
        }
        .payment-step {
            padding-bottom: 8rem;
            .title {
                font-size: 1.5rem;
                line-height: 33px;
                font-weight: 600;
                @include media-breakpoint-down(lg) {
                    font-size: 20px;
                    line-height: 27px; 
                }
            }
            .select2-container {
                text-align: center;
                font-size: 1.125rem;
                line-height: 25px;
                height: 50px;
            }
            .select2-results__option {
                font-size: 1.125rem;
            }

            .select2-container--default .select2-selection--single .select2-selection__rendered {
                line-height: 50px;
            }
            .select2-container--default .select2-selection--single .select2-selection__arrow {
                height: 30px;
            }
            .form-group {
                #cvc {
                    margin-bottom: 2rem;
                    @include media-breakpoint-down(md) {
                        margin-bottom: unset;
                    }
                }
                .col-form-label {
                    font-size: 14px;
                    line-height: 19px;
                    padding-bottom: 0px!important;
                }
                input.form-control {
                    font-size: 18px;
                    border-radius: 4px;
                    height: 50px;
                }
                .gildistimi {
                    display: flex;
                    justify-content: space-between;
                    span:not(.select2-selection__rendered):not(.select2-selection__arrow){
                        width: calc(50% - 15px)!important;
                    }
                }
                .col-form-label[for=cvc] {
                    @include media-breakpoint-down(sm) {
                        margin-top: 16px;
                    }
                }
            }
            .valid-until-month {
                .gildistimi {
                    display: flex;
                    justify-content: space-between;
                    select {
                        width: calc(50% - 15px)!important;
                        height: 50px;                       
                    }
                }
                .col-form-label[for=validUntilYear] {
                    min-height: 26px;
                    display: block;
                }
                .col-form-label[for=validUntilMonth] {
                    display: block;
                    font-size: 14px;
                    padding-bottom: 0px;
                }
            }
            .approve-checkbox {
                a {
                    color: $red;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

        }
    }
    .search-btn {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $red;
        height: 90px;
        width: 100%;
        font-size: 20px;
        line-height: 90px;
        font-weight: 600;
        color: $white;
        text-align: center;
        cursor: pointer;
        &.disabled {
            background-color: $grey-l1;
            cursor: not-allowed;
            pointer-events: none;
            span {
                display: none;
            }
        }
        &:hover {
            text-decoration: none;
        }
    }
    .search-btn:after {
        content: url(../img/arrow-next-white.svg);
        margin-left: 20px;
    }
    .select2-container {
        border: none!important;
    }
    .select2-container--open {
        &.select2-container--below {
            .select2-selection__rendered {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }
        &.select2-container--above {
            .select2-selection__rendered {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
        }
        .select2-dropdown {
            &.select2-dropdown--below {
                border: 1px solid #bebebe;
                box-shadow: none;
                margin-top: 2px;
                border-top: none;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                ul {
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
            &.select2-dropdown--above {
                border: 1px solid #bebebe;
                box-shadow: none;
                margin-bottom: 2px;
                border-bottom: none;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                ul {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }
            }
        }
    }
    .select2-selection__rendered {
        border: 1px solid #bebebe;
        border-radius: 4px;
    }
}

h3 {
    @include media-breakpoint-down(sm) {
        padding-right: 0px;
        padding-left: 0px;
    }
}