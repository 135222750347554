.loading-msg {
    padding: 20px 0px 0px;
}

.login-page {
    padding-top: 10%;
    min-height: calc(100vh - 283px);
    form {
        .login-form {
            padding: 2rem;
            .login-text{
                padding-left: 0px;
            }
            .phone-input-wrapper, .email-input-wrapper {
                margin-top: 3rem;
                width: 100%;
                height: 50px;
                border: 1px solid #D2072F;
                border-radius: 4px;
                padding-left: 0px;
                .description {
                    position: absolute;
                    top: -24px;
                    font-size: 14px;
                    color: $black;
                    line-height: 19px;
                }
                input {
                    padding-left: 0.75rem;
                    position: relative;
                    width: calc(100% - 44px);
                    height: 100%;
                    font-size: 1.5rem;
                    font-weight: 300;
                    line-height: 1.25rem;
                    color: $grey-d2;
                    outline: none;
                    border-radius: 4px;
                    padding-top: 8px;
                    border: none;
                    background: transparent;
                    &:focus {
                        font-weight: 400;
                    }
                }
                svg#mobile-icon, .mobile-icon {
                    position: absolute;
                    right: 2rem;
                    width: 1rem;
                    height: 50px;
                    top: 0;
                }
                .email-icon {
                    position: absolute;
                    height: 50px;
                    padding-top: 14px;
                    right: 1.75rem;
                }
                svg#mobile-icon, .mobile-icon, .email-icon, .username-icon, .password-icon path {
                    fill: $grey-d2;
                }
                svg#mobile-icon, .mobile-icon circle {
                    fill: $grey-d2;
                }
                input[type=number]::-webkit-inner-spin-button, 
                input[type=number]::-webkit-outer-spin-button { 
                    -webkit-appearance: none; 
                    margin: 0; 
                }
                .parsley-errors-list {
                    padding-left: 2px;
                    color: $grey-d2;
                    margin-bottom: -1rem;
                    li {
                        list-style: none;
                    }
                }
            }
            .error-message {
                padding-left: 2px;
                min-height: 1rem;
                .warning {
                    color: $red;
                }
            }
            .login-button {
                padding: 2rem;
                .btn {
                    outline: none;
                    &.btn-primary {
                        height: 60px;
                        border-radius: 30px;
                        width: 200px;
                        border: none;
                        padding: 16px 0px;
                        bottom: 0;
                        background-color: $red;
                        font-size: 18px;
                        line-height: 50px;
                        font-weight: 500;
                        color: $white;
                        text-align: center;
                        cursor: pointer;
                        position: relative;
                        span {
                            position: absolute;
                            top: 50%;
                            left: 0px;
                            transform: translateY(-50%);
                            width: calc(100% - 24px);
                        }
                        &:after {
                            content: url(../img/arrow-next-white.svg);
                            position: absolute;
                            top: 50%;
                            left: unset;
                            right: 32px;
                            transform: translateY(-50%);
                        }
                        &.disabled {
                            background-color: $grey-l1;
                            cursor: not-allowed;
                            span {
                                display: none;
                            }
                            &:after{
                                left: 50%;
                                right: unset;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                }
            }
        }
    }
}

.parsley-errors-list {
    padding-top: 8px;
}