.upsell-wrapper {
    background-color: $grey-l2;
    overflow: hidden;
    min-height: 430px;
    .upsell {
        padding: 2rem 0;
        @media (max-width: 1024px) {
                max-width: calc(100% - 60px);
        }
        .upsell-title {
            padding: 1.75rem 1.5rem;
            font-size: 20px;
            line-height: 27px;
            font-weight: 500;
        }
        .slick-slide {
            width: calc(1122px / 3) !important;
            &:nth-of-type(3) {
                padding-right: 0px;
            }
            .card-wrapper {
                width: 100%!important;
                .card-car {
                    width: 100%;
                    height: 243px;
                }
            }

        }
        .slick-track {
            width: 1500px!important;
        }
        @include media-breakpoint-down(lg) {
            .slick-slide {
                width: calc(960px / 3) !important; 
            }
        }
        @media (max-width: 1024px) {
            .slick-slider {
                padding-right: 40px;
                padding-left: 40px;
                &:after {
                    content: "";
                    width: 127px;
                    background-image: linear-gradient(to left, rgba($grey-l2, 1), rgba($grey-l2, 0));
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 40px;
                    height: 100%;
                }
            }
            .slick-track {
                height: 300px;
            }
            .slick-slide {
                width: calc(960px / 2.8) !important;
                .card-wrapper {

                    .card-car {
                        min-height: 243px;
                        .card-information {
                            img {
                                bottom: -90px;
                                right: 30px;
                                max-width: 80%;
                            }
                        }
                    }
                }
            }
            .slick-prev, .slick-next {
                width: 40px;
                height: 40px;
                border: unset;
                background: $white;
                border-radius: 50%;
                box-shadow: 2px 4px 5px rgba(210, 7, 47, 0.05);
                z-index: 1;
                top: 42%;
            }
            .slick-next:before, .slick-prev:before {
                content: none
            }
            .slick-prev {
                left: 0%;
            }
            .slick-next{
                right: 0%;
            }
            .slick-disabled {
                display: none !important;
            }
            .slick-list.no-left-padding {
                padding-left: 0 !important;
            }
        }
        @media (max-width: 900px) {
            .slick-track {
                height: 300px;
            }
        }
        @include media-breakpoint-down(sm) {
            .slick-slider {
                padding: 0px;
                &:after {
                    right: 0%;
                }
            }
            .slick-list {
                width: calc(100vw - 80px);
                margin: 0 auto;
            }
            .slick-slide {
                width: calc((100vw - 80px) / 2) !important;
                min-width: 295px;
            }
        }
        @include media-breakpoint-down(xs) {
            .slick-list {
                width: calc(100vw - 60px);
                margin: 0 auto;
                .slick-slide {
                    max-width: calc(100vw - 140px);
                }
            }
        }
        @media (max-width: 400px) {
            .slick-slider {
                &:after {
                    content: none;
                }
            }
            .slick-list {
                width: calc(100vw - 84px);
                margin: 0 auto;
                .slick-slide {
                    max-width: unset;
                    width: calc(100vw - 84px)!important;
                }
            }
        }
    }
}