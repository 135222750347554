.btn{
    &:focus {
        outline: none;
        box-shadow: none;
    }
    &.btn-primary{
        background-color: $red;
        padding: 0.4rem 2rem;
        border-radius: 100px;
        border-color: $red;
        font-size: 13px;
        font-weight: 500;
        //letter-spacing: 0.05em;
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &:not(:disabled):not(.disabled).active.active {
            background-color: $red;
            border-color: $red;
        }
        &.btn-primary:not(:disabled):not(.disabled):active, &.btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
            border-color: $white;
            background-color: $red;
        }
    }
    &.btn-search, &.btn-caps{
        height: 60px;
        border-radius: 30px;
        width: 200px;
        border: none;
        padding: 16px 0px;
        bottom: 0;
        background-color: $red;
        font-size: 18px;
        line-height: 50px;
        font-weight: 500;
        color: $white;
        text-align: center;
        cursor: pointer;
        position: relative;
        span {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            width: calc(100% - 24px);
        }
        &:after {
            content: url(../img/arrow-next-white.svg);
            position: absolute;
            top: 50%;
            left: unset;
            right: 32px;
            transform: translateY(-50%);
        }
        &.disabled {
            background-color: $grey-l1;
            cursor: not-allowed;
            span {
                display: none;
            }
            &:after{
                left: 50%;
                right: unset;
                transform: translate(-50%, -50%);
            }
        }
    }
    @include media-breakpoint-down(md) {
        &.btn-search{
            width: 100%;
            border-radius: 0;
            span {
                left: 50%;
                transform: translate(-50%, -50%);
                width: fit-content;
            }
            &:after {
                left: 50%;
                right: unset;
                transform: translate(80px, -50%);
            }
        }
    }
}

.detailed-search-btn {
    @include media-breakpoint-down(md) {
        max-width: 150px;
        width: 100%;
        span {
            font-size: 11px;
        }
        .search-icon {
            height: 16px;
            margin-bottom: 4px;
        }
    }
    &.solid {
        background-color: $red !important;
    }
}