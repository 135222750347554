$black: #121212;
$lighter_black: #222224;
$white: #ffffff;

$red: #D2072F;
$red_d1: #AD0627;

$grey-l4: rgba(255,255,255,0.35);
$grey-l3: #BEBEBE;
$grey-l2: #F2F2F4;;
$grey-l1: #E1E1E2;
$grey: #CCCCCC;
$grey-d1: #707071;
$grey-d2: #454545;

$yellow: #FE941B;