.my-contracts {
    padding: 3.75rem 0;
    @include media-breakpoint-down(md) {
        margin-bottom: 40px;
    }
    .info-section {
        padding-top: 2.5rem;
        @include media-breakpoint-down(xs) {
            margin: 0 auto;
        }
        .info-wrapper {
            padding-left: 1.875rem;
            @include media-breakpoint-down(md) {
                padding-left: 0;
            }
        }
        .slick-prev, .slick-next {
            width: 40px;
            height: 40px;
            border: unset;
            background: $white;
            border-radius: 50%;
            box-shadow: 2px 4px 5px rgba(210, 7, 47, 0.05);
            z-index: 1;
            &:before {
                font-family: unset;
                font-size: 28px;
                opacity: 1;
                color: $red;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -40%);
            }
        }
        .slick-next:before, .slick-prev:before {
            content: none
        }
        .slick-prev {
            left: -3%;
            @include media-breakpoint-down(xs) {
                left: -5%;
            }
        }
        .slick-next{
            right: -3%;
            @include media-breakpoint-down(xs) {
                right: -5%;
            }
        }

        .slick-dots {
            bottom: 10px;
            @include media-breakpoint-down(lg) {
                bottom: 40px;
            }
            @include media-breakpoint-down(sm) {
                bottom: 20px;
            }
            li {
                margin: 0px;
                button:before {
                    color: $red;
                    font-size: 10px;
                    opacity: 0.35;
                }
                &.slick-active {
                    button:before {
                        opacity: 1;
                        color: $red;
                    }
                }
            } 
        }
        .car-card {
            .card-wrapper{
                margin-bottom: 0px;
                @include media-breakpoint-down(sm) {
                    max-width: 100% !important;
                }
                .card-car {
                    .card-body {
                        border: none;
                        .card-information {
                            border: 4px solid white;
                            background-color: $grey-l2;
                            padding: 48px 40px 160px 40px;
                            @include media-breakpoint-down(xs) {
                                padding: 48px 40px 96px 40px;
                            }
                            img {
                                mix-blend-mode: normal;
                                bottom: -200px;
                                @include media-breakpoint-down(lg) {
                                    bottom: -150px;
                                }
                                @include media-breakpoint-down(sm) {
                                    max-width: 85%;
                                    top: 50%;
                                }
                                @include media-breakpoint-down(xs) {
                                    max-width: 80%;
                                    right: 10%;
                                }
                                @media (max-width: 500px) {
                                    top: 60%;
                                }
                                @media (max-width: 400px) {
                                    top: 65%;
                                    max-width: 85%;
                                    right: 7.5%;
                                }
                            }
                            .description {
                                border-top: 2px $grey-l1 solid;
                                display: grid;
                                grid-template-columns: 80px 80px;
                                grid-column-gap: 20px;
                                div {
                                    display: grid;
                                    grid-template-rows: auto auto;
                                    grid-row-gap: 4px;
                                    padding-top: 10px;
                                    &.year {
                                        border-right: solid 2px $grey-l1;
                                    }
                                }
                            }
                            .card-title {
                                margin-bottom: 16px;
                            }
                        }
                        .white-bottom {
                            height: 153px;
                            background-color: $white;
                            @include media-breakpoint-down(xs) {
                                height: 120px;
                            }
                        }
                    }
                }
            }
        }
        .car-specific-info {
            background-color: $white;
            padding: 1rem 1.25rem;
            border-radius: 20px;
            height: 100%;
            @include media-breakpoint-down(md) {
                margin-top: 40px;
            }
            &>div {
                display: grid;
                grid-template-columns: 1fr auto;
                border-bottom: 1px solid $grey-l1;
                padding: 20px 0px;
                @include media-breakpoint-only(lg) {
                    grid-template-columns: 1fr;
                    grid-template-rows: auto auto;
                    padding: 16px 8px;
                }
                @include media-breakpoint-down(xs) {
                    grid-template-columns: 1fr;
                    grid-template-rows: auto auto;
                    padding: 16px 8px;
                }
            }
            .month {
                display: grid;
                grid-template-rows: auto auto;
                grid-template-columns: auto;
                div {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    grid-template-rows: auto auto;
                    grid-row-gap: 4px;
                    span {
                        &:nth-child(1) {
                            grid-area: 1/1/2/3;
                        }
                        &:nth-child(2) {
                            grid-area: 2/1/3/3;
                        }
                    }
                }
            }

            .appointments {
                display: grid;
                grid-template-rows: auto auto;
                grid-row-gap: 16px;
                border-bottom: none;
                grid-template-columns: 1fr;
                @include media-breakpoint-down(md){
                    grid-template-columns: 1fr 1fr;
                }
                @include media-breakpoint-down(xs){
                    grid-template-columns: 1fr;
                }
                .title {
                    grid-area: 1/1/2/3;
                }
                div {
                    width: 100%;
                    height: 40px;
                    border: 1px solid $grey-l1;
                    border-radius: 20px;
                    position: relative;
                    a {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        color: $black;
                        font-weight: 500;
                        border-radius: 20px;
                        &:hover {
                            color: $black;
                            text-decoration: none;
                        }
                        span {
                            position: absolute;
                            top: 50%;
                            left: 16%;
                            transform: translate(0,-50%);
                            @include media-breakpoint-down(lg) {
                                left: 6%;
                            }
                            @include media-breakpoint-down(md) {
                                left: 20%;
                            }
                            @include media-breakpoint-down(sm){
                                left: 9%;
                            }
                            @include media-breakpoint-down(xs){
                                left: 22%;
                            }
                        }
                    }
                }
            }
            .title {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
            }
            .numbers {
                font-weight: 500;
                font-size: 18px;
                line-height: 17px;
            }
            .text {
                font-size: 16px;
                font-weight: 400;
            }
            .smaller-text {
                font-size: 13px;
                color: $grey-d1;
                @include media-breakpoint-only(lg) {
                    font-size: 10px;
                }
                @include media-breakpoint-down(xs) {
                    font-size: 10px;
                }
            }
        }
    }
}
.appointments-iframe {
    iframe {
        min-height: 75vh;
        border: none;
    }
}