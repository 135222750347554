.carsale {
    .info-form-wrapper {
        form {
            width: 100%;
            @include media-breakpoint-down(lg) {
                .carsale-info-form {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
            .input-wrapper {
                margin-top: 1rem;
                width: 100%;
                height: 50px;
                border: 1px solid $red;
                border-radius: 4px;
                padding-left: 0px;
                position: relative;
                span {
                    position: absolute;
                    top: -24px;
                    font-size: 14px;
                    color: #121212;
                    line-height: 19px;
                }
                input {
                    padding-left: 0.75rem;
                    position: relative;
                    width: calc(100% - 44px);
                    height: 100%;
                    font-size: 1.5rem;
                    font-weight: 300;
                    line-height: 1.25rem;
                    color: #454545;
                    outline: none;
                    border-radius: 4px;
                    padding-top: 8px;
                    border: none;
                    background: transparent;
                }
                input[type=number]::-webkit-inner-spin-button, 
                input[type=number]::-webkit-outer-spin-button { 
                    -webkit-appearance: none; 
                    margin: 0; 
                }
                svg#mobile-icon, .mobile-icon {
                    position: absolute;
                    right: 2rem;
                    width: 1rem;
                    height: 50px;
                    top: 0;
                }
                .email-icon {
                    position: absolute;
                    height: 50px;
                    padding-top: 14px;
                    right: 1.75rem;
                }
            }
        }
    }
    .sub-car {
        .single-car {
            .detailed-car-information {
                .extras-wrapper {
                    padding-right: 70px;
                    padding-bottom: 30px;
                }
            }
            .car-body {
                .button-wrapper {
                    padding-top: 2rem;
                    a {
                        color: $white;
                    }
                }
            }
        }
    }
}
.upsell-wrapper.carsale {
    .upsell .slick-track {
        width: 20000px !important;
    }
    .card-wrapper:hover {
        cursor: pointer;
    }
    @include media-breakpoint-down(lg) {
        .slick-prev {
            left: -2%;
        }
        .slick-next {
            right: -2%;
        }
    }
}