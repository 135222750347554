@import "colors";   
@import "custom";
@import "node_modules/bootstrap/scss/bootstrap";
@import "base";

/* Components */
@import "buttons";
@import "header";
@import "footer";
@import "car_card";
@import "car_detail_modal";
@import "my_contracts";
@import "my_information";
@import "order_car";
@import "sub_car_chooser";
@import "detailed_car_slider";
@import "price_bar";
@import "spinner";
@import "upsell_container";

/* Blocks */
@import "blocks/header_block";
@import "blocks/paragraph_image_block";
@import "blocks/paragraph_block";
@import "blocks/contact_block";
@import "blocks/quote_block";
@import "blocks/employees";
@import "blocks/terms";

/* Pages */
@import "home_page";
@import "standard_page";
@import "search";
@import "login_page";
@import "carsale_detail";
@import "appointment-iframe-page";

/* Vendor */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "rc_slider";