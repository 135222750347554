header{
    nav{
        background-color: $red;
        height: 60px;
        z-index: 9999;
        .navbar-brand {
            img {
                max-width: 97px;
            }
        }
        .nav-items {
            position: absolute;
            right: 80px;
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            .nav-item {
                color: $white;
                padding-left: 20px;
                padding-right: 20px;
            }
            .bookings {
                position: relative;
                padding-left: 52px;
                padding-right: 20px;
                border-left: 1px solid $grey-l4;
                img {
                    position: absolute;
                    left: 0px;
                    top: 3px;
                    padding-left: 20px;
                }
            }
            .language {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                position: relative;
                display: inline-block;
                .dropbtn {
                    color: $white;
                    font-weight: 500;
                    border: 1px solid $white;
                    border-radius: 13.5px;
                    font-size: 14px;
                    cursor: pointer;
                    padding: 4px 20px 4px 14px;
                    background-color: $red;
                    position: relative;
                    width: 56px;
                    height: 26px;
                    span {
                        height: 6px;
                        width: 6px;
                        border: 1px solid $white;
                        position: absolute;
                        top: 8px;
                        right: 12px;
                        border-left: none;
                        border-top: none;
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                    } 
                }
                .dropdown-content {
                    display: none;
                    position: absolute;
                    background-color: $white;
                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                    border-radius: 7px;
                    margin-top: 8px;
                    text-align: center;
                    &.show {display:block;}
                    .arrow-up {
                        height: 6px;
                        width: 6px;
                        border: 1px solid $white;
                        position: absolute;
                        top: -3px;
                        right: 24px;
                        border-left: none;
                        border-top: none;
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        background-color: $white;
                    }
                }
                .dropdown-content a {
                    color: $grey-d2;
                    padding: 6px 18px;
                    text-decoration: none;
                    display: block;
                    &:first-of-type {
                        padding-top: 12px;
                    }
                    &:last-of-type {
                        padding-bottom: 12px
                    }
                    &.active {
                        font-weight: 600;
                        color: $black;
                    }
                }
            }
            
        }
        // .navbar-collapse {
        //     background-color: $red;
        //     flex-basis: unset;
        //     flex-grow: unset;
        //     align-items: unset;
        //     width: 300px;
        //     position: absolute;
        //     top: 60px;
        //     right: 0;
        //     .navbar-nav {
        //         margin-left: 2rem !important;
        //         .nav-item {
        //             margin: unset;
        //         }
        //     }
        // }
        // @include media-breakpoint-down(md) {
        // }
        .navbar-nav {
            &:first-child {
                padding-left: 100px;
            }
            width: 70%;
            &.navbar-secondary {
                width: 70%;
                // .nav-item {
                //     &:first-child {
                //         margin-left: auto;
                //     }
                // }
            }
            .nav-item {
                .nav-link {
                    color: white;
                    text-transform: uppercase;
                    font-size: 0.75rem;
                    line-height: 1.5rem;
                    font-weight: 500;
                }
                #my-pages-header-link {
                    display: none;
                }
            }
        }
        svg {
            margin-left: 10px;
            &.user-icon {
                margin-top: -4px;
            }
            path {
                fill: $white;
            }
        }
        .search-wrapper{
            height: 60px;
            width: 300px;
            margin-right: -15px;
            .btn-search{
                width: 100%;
                height: 100%;
                text-transform: uppercase;
                font-size: 0.75rem;
                line-height: 2.75rem;
                font-weight: 600;
                &:after {
                    content: '';
                }
            }
        }
    }
    button.navbar-toggler{
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        z-index: 5;
        transition: (right .5s linear);
        &:focus {
            outline: none;
        }
        @include media-breakpoint-up(lg) {
            &:hover {
                span.navbar-toggler-icon {
                    span:nth-child(1) {
                        width: 24px;
                        right: 3px
                    }
                    span:nth-child(4) {
                        width: 24px;
                        right: 3px;
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            right: 20px;
        }
        span.navbar-toggler-icon{
            width: 23px;
            height: 13px;
            cursor: pointer;
            position: relative;
            &.open {
                span {
                    &:nth-child(1) {
                        top: 11px;
                        width: 0% !important;
                        left: 50%;
                    }
                    &:nth-child(2) {
                        -webkit-transform: rotate(45deg);
                        -moz-transform: rotate(45deg);
                        -o-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                    
                    &:nth-child(3) {
                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        -o-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                    }
                    
                    &:nth-child(4) {
                        top: 11px;
                        width: 0% !important;
                        left: 50%;
                    }
                }
            }
            span {
                background: $white;
                display: block;
                position: absolute;
                height: 1px;
                width: 100%;
                border-radius: 9px;
                opacity: 1;
                right: 0;
                transition: .20s ease-in-out;
                &:nth-child(1) {
                    top: 0px;
                }
                &:nth-child(2), &:nth-child(3) {
                    top: 6px;
                }
                
                &:nth-child(4) {
                    top: 12px;
                }
            }
        }
    }
    .hamburger-menu-wrapper {
        position: fixed;
        filter: drop-shadow(-1px 12px 40px rgba(0, 0, 0, 0.25));
        //position: absolute;
        top: 0;
        z-index: 10000;
        right: 0;
        width: 0%;
        max-width: 500px;
        transition: 0.4s ease-in-out;
        &.open {
            transition: width 0.4s ease-in-out;
            width: 99%;
            .menu-wrapper {
                display: block;
                button.navbar-toggler {
                    margin-right: -35px;

                }
                .navbar-nav:not(.navbar-secondary) {
                    padding-top: 40px;
                }
            }
        }
    }
    .hamburger-menu {
        background-color: white;
        clip-path: polygon(100% 160%, 100% 0%, 1% 0%);
        height: 100vh;
        button.navbar-toggler{
            span.navbar-toggler-icon{
                span {
                    background-color: $grey-d1;
                }
            }
        }
        .menu-wrapper {
            width: 100%;
            margin-left: auto;
            text-align: right;
            display: none;
            padding-top: 18px;
            padding-right: 50px;
            .navbar-nav {
                .nav-item {
                    .log-in, .sign-in {
                        position: absolute;
                        top: 12px;
                        right: 90px;
                    }
                    .log-out {
                        padding-top: 60px;
                    }
                }
            }
            a {
                color: $grey-d1;
                font-size: 18px;
                line-height: 25px;
                svg {
                    path {
                        fill: $grey-d1;
                    }
                }
            }
        }
    }
}