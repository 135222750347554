.page-standardpage{
    section{
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
        .container{
            &:last-child{
                &::after{
                    content: none !important;
                }
            }
        }
    }
    footer{
        border-top: none;
        background-color: $grey-l2;
        z-index: -1;
    }
}